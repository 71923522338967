import { Container, AppBar, Toolbar, Typography, IconButton, Button, Box, Tabs, Tab, Card, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio, Skeleton } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, toReal, createNewTheme } from '../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Enderecos() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [isLogged, setIsLogged] = useState(false);
  const [enderecoRetirada, setEnderecoRetirada] = useState("");
  const [enderecoEntrega, setEnderecoEntrega] = useState("");
  const [nomeEnderecoEntrega, setNomeEnderecoEntrega] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState(0);
  const [disableEntrega, setDisableEntrega] = useState(0);
  const [disableRetirada, setDisableRetirada] = useState(0);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [valorEntrega, setValorEntrega] = useState("-");
  const [endereco, setEndereco] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msgSnack, setMsgSnack] = React.useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/carrinho", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      handleBack();
    };

    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleSelecionarEndereco = () => {
    navigate("/selecionar-endereco", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handlePagamentos = () => {
    if (enderecoEntrega) {
      Cookies.set('eEA', enderecoEntrega, { expires: 7 })
    }
    Cookies.set('tE', tipoEntrega, { expires: 7 });
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      Cookies.set('fmP', "pontos", { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    } else {
      navigate("/pagamentos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
  };

  const handleChange = (event, newValue) => {
    setTipoEntrega(newValue);
    const valorTotal = Cookies.get('vlT');
    setValorTotalCarrinho(valorTotal);

    (newValue === 1) ? setButtonEnabled(false) : getTaxa(newValue);
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    getInfos();
    const entrega = Cookies.get('tE');
    if (entrega) {
      setTipoEntrega(parseInt(entrega));
      setButtonEnabled(false)
    }
    getTaxa(entrega);
    const valorTotal = Cookies.get('vlT');
    if (valorTotal == 0) {
      Cookies.set('fmP', "Combinar", { expires: 7 });
      Cookies.remove('sT');
      Cookies.set('tE', 1, { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
    setValorTotalCarrinho(valorTotal)
    

    const entregasAtivas = Cookies.get('tD');
    entregasAtivas.includes("Entrega") ? setDisableEntrega(false) : setDisableEntrega(true);
    entregasAtivas.includes("Retirada") ? setDisableRetirada(false) : setDisableRetirada(true);
    if (!entregasAtivas.includes("Entrega")) {
      setButtonEnabled(false)
      setTipoEntrega(1)
    }
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/estabelecimentos.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const informacoes = response.data[0]
        const enderecoArr = informacoes.endereco.split("___")
        setEnderecoRetirada(enderecoArr[0])
        Cookies.set('eR', enderecoArr[0], { expires: 7 });
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getTaxa = (tipo) => {
    !tipo ? tipo = 0 : console.log(":..")
    const isLogged = Cookies.get('isLogged');
    setIsLogged(isLogged)
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))

    const enderecoEntregaAtual = Cookies.get('eEA');
    enderecoEntregaAtual ? setButtonEnabled(false) : setButtonEnabled(true)
    setEnderecoEntrega(enderecoEntregaAtual)
    const nomeEndereco = Cookies.get('nmEA');
    setNomeEnderecoEntrega(nomeEndereco)
    if (enderecoEntregaAtual) {
      const mainId = Cookies.get('Md');
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/retornar-taxa.php`, {
        idestabelecimentos: mainId,
        endereco: enderecoEntregaAtual
      })
        .then(response => {
          response = response.data
          if (!response) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
            return
          }

          let bloquearCarrinho = Cookies.get('bCrt');
          if (bloquearCarrinho) {
            setButtonEnabled(false)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta == "combinar") {
            setButtonEnabled(false)
            Cookies.set('txE', "combinar", { expires: 7 });
            setValorTotalCarrinho(Cookies.get('vlT'))
            setValorEntrega("A Combinar")
          } else if (response.resposta == "0,00") {
            setValorTotalCarrinho(Cookies.get('vlT'))
            setButtonEnabled(false)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta.includes("endereço invalido")) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("falha consultar")) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("distancia ultrapassa")) {
            setButtonEnabled(true)
            Cookies.remove('txE')
            handleSnack('Desculpe, o estabelecimento não trabalha com entregas no endereço indicado.')
          } else if (parseFloat(response.resposta.replaceAll(",", ".")) > 0 && parseInt(tipo) === 0) {
            Cookies.remove("tE")
            setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.')) + parseFloat(response.resposta.replaceAll(".", "").replaceAll(",", '.'))))
            setButtonEnabled(false)
            setValorEntrega(`R$ ${response.resposta}`)
            Cookies.set('txE', response.resposta, { expires: 7 });
          } else if(parseInt(tipo) === 1) {
            console.log("...")
          } else {
            setButtonEnabled(true)
            handleSnack('Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente. Código (1001)')
          }
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    } else {
      handleCloseLoading();
    }
  }


  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Opções de entrega
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        <Box sx={{ width: '100%' }}>
          <Tabs value={tipoEntrega} onChange={handleChange} variant="fullWidth" >
            <Tab label="Entrega" disabled={disableEntrega} {...a11yProps(0)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
            <Tab label="Retirada" disabled={disableRetirada} {...a11yProps(1)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
          </Tabs>
          <CustomTabPanel value={tipoEntrega} index={0}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Entregar em:
              <div style={{ height: "10px" }}></div>
              {enderecoEntrega ? (
                <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    avatar={
                      <FormControl value={endereco}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel checked value="2" control={<Radio />} />
                        </RadioGroup>
                      </FormControl>
                    }
                    action={
                      <Button variant="text" aria-label="settings" style={{ textTransform: "none", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                        Alterar
                      </Button>
                    }
                    title={nomeEnderecoEntrega}
                    titleTypographyProps={{ fontSize: "12px", fontWeight: "500", marginLeft: "-20px", color: "#999" }}
                    subheader={enderecoEntrega}
                    subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", marginLeft: "-20px", color: "#1B1B1B" }}
                  />
                </Card>
              ) : (
                <Button variant="outlined" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                  Definir endereço de entrega
                </Button>
              )}

              <div style={{ height: "12px" }}></div>
              Hoje, {tempoEntrega}
              <div style={{ height: "10px" }}></div>

              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  title="Taxa de entrega"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={valorEntrega}
                  subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>
            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={tipoEntrega} index={1}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Retirar em:
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title="Local"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={enderecoRetirada ? (
                    enderecoRetirada
                  ) : (
                    <Skeleton animation="wave" height={30} width="100%" />
                  )}
                  subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>

            </Typography>
          </CustomTabPanel>
        </Box>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
            Total com entrega
          </Typography>
          <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
            {valorTotalCarrinho === "0" && Cookies.get("vPt") ? (
              <>
                Resgate
              </>
            ) : (
              <>
                {valorTotalCarrinho === "0" ?
                  <>A Consultar</>
                  :
                  <> R$ {valorTotalCarrinho} </>
                }
              </>
            )}
          </Typography>
          <div style={{ height: "35px" }}></div>
          <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "none", fontWeight: "700" }} onClick={handlePagamentos}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Enderecos;