import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme, getDataMes, maskReais } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, ListItemText, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Add, AttachFileOutlined, Close, CloseOutlined, FilterList, NorthEastOutlined, SearchOutlined, SouthEastOutlined } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MaterialUISwitch } from '../../components/utils';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const formas = [
  'Todas as formas de pagamento',
  'Crédito',
  'Débito',
  'Pix',
  'Dinheiro',
  'Outro',
];

const tipos = [
  'Todos os tipos',
  'Compra',
  'Venda',
  'Saldo Inicial',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Despesas({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {
  
  const { windows } = props;
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [despesasOriginal, setDespesasOriginal] = useState();
  const [despesas, setDespesas] = useState();
  const [despesaSelecionada, setDespesaSelecionada] = useState(-1);
  const [pesquisar, setPesquisar] = useState("");
  const [timestampInicio, setTimestampInicio] = useState("");
  const [timestampFim, setTimestampFim] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [isFiltrando, setIsFiltrando] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nomeDespesa, setNomeDespesa] = useState('');
  const [valorDespesa, setValorDespesa] = useState('');
  const [formaDePagamento, setFormaDePagamento] = useState('');
  const [tipoDespesa, setTipoDespesa] = useState('');
  const [categoriaDespesa, setCategoriaDespesa] = useState('');
  const [comentarioDespesa, setComentarioDespesa] = useState('');
  const [refletirFechamento, setRefletirFechamento] = useState(true);
  const [openModalFiltros, setOpenModalFiltros] = useState(false);
  const [filtroPeriodoDe, setFiltroPeriodoDe] = useState(null);
  const [filtroPeriodoAte, setFiltroPeriodoAte] = useState(null);
  const [dataDespesa, setDataDespesa] = useState(null);
  const [tiposDespesaFiltro, setTiposDespesaFiltro] = useState([]);
  const [formasPagamentoFiltro, setFormasPagamentoFiltro] = useState([]);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const selectTiposDespesaFiltro = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('Todos os tipos')) {
      setTiposDespesaFiltro(tiposDespesaFiltro.length === tipos.length ? [] : tipos);
    } else {
      setTiposDespesaFiltro(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const selectFormasPagamentosFiltro = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('Todas as formas de pagamento')) {
      setFormasPagamentoFiltro(formasPagamentoFiltro.length === formas.length ? [] : formas);
    } else {
      setFormasPagamentoFiltro(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const handleOpenFiltros = (event) => {
    setOpenModalFiltros(true);
  };


  const handleNome = (event) => {
    setNomeDespesa(event.target.value);
  };

  const handleValor = (event) => {
    setValorDespesa(maskReais(event.target.value));
  };

  const handleFormaPagamento = (event) => {
    setFormaDePagamento(event.target.value);
  };

  const handleTipoDespesa = (event) => {
    setTipoDespesa(event.target.value);
  };

  const handleCategoria = (event) => {
    setCategoriaDespesa(event.target.value);
  };


  const handleComentario = (event) => {
    setComentarioDespesa(event.target.value);
  };

  const handleRefletir = (event) => {
    setRefletirFechamento(!refletirFechamento);
  };

  const handleCloseModalRelatorio = () => {
    setOpenModalFiltros(false)
  };

  const handlePesquisar = (event) => {
    setDespesas(despesasOriginal.filter(item => item.NM_FNC.toLowerCase().includes(event.target.value.toLowerCase())))
    setPesquisar(event.target.value);
  };

  const handleFiltrar = () => {
    handleOpenLoading();
    if (tiposDespesaFiltro && tiposDespesaFiltro.length === 0) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar pelo menos um tipo para filtrar.`)
      return
    }
    if (formasPagamentoFiltro && formasPagamentoFiltro.length === 0) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar pelo menos uma forma de pagamento para filtrar.`)
      return
    }
    if (!filtroPeriodoDe || !filtroPeriodoAte) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar as datas de inicio e fim para filtrar.`)
      return
    }
    handleCloseLoading()

    let tiposEnviar = `'${tiposDespesaFiltro.join("','")}'`
    let formasEnviar = `'${formasPagamentoFiltro.join("','")}'`

    axios.get(`${urlBase}/despesas-filtro.php?tipos=${tiposEnviar}&formas=${formasEnviar}&de=${filtroPeriodoDe.unix()}&ate=${filtroPeriodoAte.unix() + 86399}`, {
      idestabelecimentos: "1"
    })
      .then(response => {

        response = response.data
        if (response) {
          setDespesasOriginal(response)
          setDespesas(response)
          setOpenModalFiltros(false)
          setIsFiltrando(true)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });

  }

  const getDespesas = (url, inicio, fim) => {
    handleOpenLoading()
    setIsFiltrando(false)
    axios.get(`${url}/despesas.php?inicio=${inicio}&fim=${fim}`, {
      idestabelecimentos: "1"
    })
      .then(response => {

        response = response.data
        if (response) {
          if (!despesasOriginal) {
            setDespesasOriginal(response)
          }
          setDespesas(response)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  useEffect(() => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');
    setUrlBase(urlBase)

    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999);
    const timestampFim = Math.floor(hoje.getTime() / 1000);
    const timestampInicio = timestampFim - 86399;
    setTimestampInicio(timestampInicio)
    setTimestampFim(timestampFim)
    getDespesas(urlBase, timestampInicio, timestampFim)
  }, []);

  const validaZerodespesas = (despesas) => {
    if (despesas && despesas.length === 0) {
      return true
    }
    return false
  }

  const handleNovaDespesa = () => {
    if (despesaSelecionada === 0) {
      return
    }
    setDespesaSelecionada(0)
    setNomeDespesa("")
    setValorDespesa("")
    setFormaDePagamento("")
    setDataDespesa(null)
    setTipoDespesa("")
    setCategoriaDespesa("")
    setComentarioDespesa("")
    setRefletirFechamento(true)
  }

  const handleDespesa = (cliente) => {
    setNomeDespesa(cliente.NM_FNC)
    setValorDespesa(cliente.QT_DSP)
    setFormaDePagamento(cliente.FM_PG)
    const data = dayjs(cliente.DT_DSP * 1000);
    setDataDespesa(data)
    setTipoDespesa(cliente.TP_CMP)
    setCategoriaDespesa(cliente.CT_FNC)
    setComentarioDespesa(cliente.CMT_DSP)
    setRefletirFechamento(cliente.RFT_CT === "S")
    setDespesaSelecionada(cliente)
  }

  const getDataFiltro = (filtro) => {
    switch (filtro) {
      case "de":
        return filtroPeriodoDe.format('DD/MM/YYYY')
      case "ate":
        return filtroPeriodoAte.format('DD/MM/YYYY')
    }
  };

  const handleCancelarFiltros = () => {
    getDespesas(urlBase, timestampInicio, timestampFim);
    setIsFiltrando(false)
  };

  const handleAtualizaDespesa = () => {

    const tipoAcaoO = despesaSelecionada.IDDSP_ML > 0 ? "atualizar" : "adicionar"
    if (!dataDespesa || (dataDespesa && dataDespesa.length === 0)) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar a data do lançamento para ${tipoAcaoO}.`)
      return
    }

    if (!nomeDespesa && nomeDespesa.length < 2) {
      update("", `${Date.now()}___warning___O nome precisa ser preenchido com pelo menos 2 caracteres.`)
      return;
    }

    if (!valorDespesa && valorDespesa.length < 3) {
      update("", `${Date.now()}___warning___O valor precisa ser preenchido.`)
      return;
    }

    if (!formaDePagamento) {
      update("", `${Date.now()}___warning___Você precisa selecionar a forma de pagamento.`)
      return;
    }

    if (!tipoDespesa) {
      update("", `${Date.now()}___warning___Você precisa selecionar o tipo.`)
      return;
    }

    const tipoAcao = despesaSelecionada.IDDSP_ML > 0 ? "atualizado" : "adicionado"
    const post_url = despesaSelecionada.IDDSP_ML > 0 ? `${urlBase}/editar-despesa.php` : `${urlBase}/cadastro-despesa.php`;
    const json = JSON.stringify({
      iddespesa: despesaSelecionada.IDDSP_ML ? despesaSelecionada.IDDSP_ML : 0,
      entrada: dataDespesa.unix(),
      valor: valorDespesa,
      formaPagamento: formaDePagamento,
      nome: nomeDespesa,
      tipoDespesa: tipoDespesa,
      categoria: categoriaDespesa,
      refletirFechamento: refletirFechamento ? "S" : "N",
      comentario: comentarioDespesa
    });

    axios.post(post_url, json)
      .then(resp => {
        if (resp.data.resposta && resp.data.resposta === "sucesso") {
          update("", `${Date.now()}___success___Item ${tipoAcao} com sucesso.`)
          setDespesaSelecionada(-1)
          getDespesas(urlBase, timestampInicio, timestampFim);
        }
      })
  }

  const handleRemoverDespesa = () => {

    const post_url = `${urlBase}/deletar-despesa.php`;
    const json = JSON.stringify({
      iddespesa: despesaSelecionada.IDDSP_ML,
    });

    axios.post(post_url, json)
      .then(resp => {
        if (resp.data.resposta && resp.data.resposta === "sucesso") {
          update("", `${Date.now()}___success___Item deletado com sucesso.`)
          setDespesaSelecionada(-1)
          getDespesas(urlBase, timestampInicio, timestampFim);
        }
      })
  }

  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <Dialog
        open={openModalFiltros}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 640 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <FilterList />&nbsp;&nbsp;Filtrar
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalRelatorio}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>

          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            Tipo
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={tiposDespesaFiltro}
              onChange={selectTiposDespesaFiltro}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {tipos.map((stats) => (
                <MenuItem key={stats} value={stats}>
                  <Checkbox checked={tiposDespesaFiltro.indexOf(stats) > -1} />
                  <ListItemText primary={stats} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 16 }} />

          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            Formas de pagamento
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={formasPagamentoFiltro}
              onChange={selectFormasPagamentosFiltro}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {formas.map((stats) => (
                <MenuItem key={stats} value={stats}>
                  <Checkbox checked={formasPagamentoFiltro.indexOf(stats) > -1} />
                  <ListItemText primary={stats} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>De</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoDe}
                  onChange={(newValue) => setFiltroPeriodoDe(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Até</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoAte}
                  onChange={(newValue) => setFiltroPeriodoAte(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseModalRelatorio} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          <Button variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={handleFiltrar}>Filtrar</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute", overflow: "hidden" }}>
        <Grid container spacing={2} style={{ padding: "16px 0px 16px 16px" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={7} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", height: "100vh" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Fluxo de caixa</Typography>
                  <div style={{ marginLeft: "auto", paddingRight: 16 }}>
                    <Tooltip title={`Filtrar`}>
                      <IconButton onClick={handleOpenFiltros} size="medium" color="inherit" style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48 }}>
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                    <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleNovaDespesa} endIcon={<Add />}>Adicionar lançamento</Button>
                  </div>
                </div>

                <div>
                  <div style={{ height: 40 }} />
                  <Grid container spacing={2} style={{ maxHeight: "calc(100vh - 192px)", overflow: "auto", paddingBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
                    {despesas &&
                      <>
                        {validaZerodespesas(despesasOriginal) ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 328px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                                <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                                <div style={{ height: "10px" }}></div>

                                Não há lançamentos para listar

                                <div style={{ height: "20px" }}></div>
                              </div>
                            </Grid>
                          </>
                          :
                          <>
                            <TextField
                              fullWidth
                              hiddenLabel
                              value={pesquisar}
                              onChange={handlePesquisar}
                              variant="outlined"
                              size="large"
                              placeholder="Pesquisar por Nome"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <InputAdornment style={{ color: "#444" }}>
                                      <SearchOutlined />
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                            />
                            <Divider style={{ width: "100%" }} />
                            <div style={{ height: 20 }} />
                          </>
                        }

                        {isFiltrando &&
                          <Grid item xs={12} sm={12} md={12} style={{ padding: 0 }}>
                            <div style={{ height: 20 }} />
                            <Typography style={{ fontWeight: "500", fontSize: 14, color: "#464545", background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                                <Grid item>
                                  Limpar filtros
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>De {getDataFiltro("de")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>Até {getDataFiltro("ate")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>{tiposDespesaFiltro.join(", ")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>{formasPagamentoFiltro.join(", ")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                              </Grid>
                            </Typography>
                            <div style={{ height: 10 }} />
                          </Grid>
                        }

                        <table style={{ width: "100%", marginTop: 16 }}>
                          <tr>
                            <th style={{ width: 60 }}></th>
                            <th style={{ textAlign: "left" }}>Nome</th>
                            <th style={{ textAlign: "left", width: 200 }}>Categoria</th>
                            <th style={{ textAlign: "center", width: 100 }}>Data</th>
                            <th style={{ textAlign: "center", width: 120 }}>Valor</th>
                          </tr>
                        </table>
                        {despesas.map((cliente) => (
                          <Tooltip title={"Clique para ver detalhes"}>
                            <table style={{ width: "100%", textAlign: "left", background: "#F2F2F2", borderRadius: 8, marginBottom: 6, cursor: "pointer" }} onClick={() => handleDespesa(cliente)}>
                              <tr>
                                <td style={{ padding: 6, width: 60, textAlign: "center" }}>
                                  {cliente.TP_CMP === "compra" ?
                                    <SouthEastOutlined style={{ color: "#F94A3D" }} />
                                    :
                                    <NorthEastOutlined style={{ color: "#6B9F29" }} />
                                  }
                                </td>
                                <td style={{ textAlign: "left" }}>{cliente.NM_FNC}</td>
                                <td style={{ textAlign: "left", width: 200 }}>{cliente.CT_FNC}</td>
                                <td style={{ textAlign: "center", width: 100 }}>{getDataMes(cliente.DT_DESPESA)}</td>
                                <td style={{ textAlign: "center", width: 120 }}>
                                  <Typography style={{ background: cliente.TP_CMP === "compra" ? "#F94A3D" : "#6B9F29", fontWeight: "bold", paddingTop: 2, paddingBottom: 2, borderRadius: 4, margin: 10, color: "white" }}>
                                    R$ {toReal(parseFloat(cliente.QT_DSP.replaceAll(".", "").replaceAll(",", '.')))}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </Tooltip>
                        ))}
                      </>
                    }

                  </Grid>
                </div>
              </Grid>

              {despesaSelecionada === -1 ?
                <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                    <img src="https://dedicado.mely.online/imagens/no-order-est.png" style={{ width: 155 }} />

                    <div style={{ height: "10px" }}></div>

                    Selecione um lançamento ao lado para ver mais detalhes

                    <div style={{ height: "20px" }}></div>
                  </div>
                </Grid>
                :
                <>
                  <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                    <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{despesaSelecionada.NM_FNC ? despesaSelecionada.NM_FNC : "Novo lançamento"}
                      <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setDespesaSelecionada(-1)}>
                        <Close />
                      </IconButton>
                    </Typography>
                    <div style={{ height: 10 }} />
                    <Divider style={{ width: "120%", marginLeft: -16 }} />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Data do lançamento <b style={{ color: "#A51D1D" }}>*</b></Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                      <DatePicker
                        value={dataDespesa}
                        onChange={(newValue) => setDataDespesa(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>

                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Categoria
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={categoriaDespesa}
                      onChange={handleCategoria}
                      variant="outlined"
                      size="large"
                      placeholder="Insira uma categoria (Ifood, Fornecedor, Outros)"
                      type="text"
                    />

                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Nome <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={nomeDespesa}
                      onChange={handleNome}
                      variant="outlined"
                      size="large"
                      placeholder="Insira o nome do fornecedor ou cliente"
                      type="text"
                    />

                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Descrição
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={comentarioDespesa}
                      onChange={handleComentario}
                      variant="outlined"
                      size="large"
                      placeholder="Insira um descrição"
                      type="text"
                    />
                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Valor <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={valorDespesa}
                      onChange={handleValor}
                      variant="outlined"
                      size="large"
                      placeholder="Insira o valor"
                      type="text"
                    />

                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Forma de pagamento <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        value={formaDePagamento}
                        onChange={handleFormaPagamento}
                      >
                        <MenuItem value={"crédito"}>Crédito</MenuItem>
                        <MenuItem value={"débito"}>Débito</MenuItem>
                        <MenuItem value={"pix"}>Pix</MenuItem>
                        <MenuItem value={"dinheiro"}>Dinheiro</MenuItem>
                        <MenuItem value={"outro"}>Outro</MenuItem>

                      </Select>
                    </FormControl>

                    <div style={{ height: 16 }}></div>

                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Tipo <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        value={tipoDespesa}
                        onChange={handleTipoDespesa}
                      >
                        <MenuItem value={"compra"}>Compra</MenuItem>
                        <MenuItem value={"venda"}>Venda</MenuItem>
                        <MenuItem value={"saldoinicial"}>Saldo Inicial do Caixa</MenuItem>
                      </Select>
                    </FormControl>

                    <div style={{ height: 16 }}></div>

                    <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                      <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600" }}>
                        <b>Refletir no fechamento?<br /><b style={{ fontSize: 14, fontWeight: "400" }}>Marque essa opção se deve refletir na contagem do fechamento</b></b>
                        <FormControlLabel style={{ marginLeft: "auto" }}
                          control={<MaterialUISwitch sx={{ m: 1 }} checked={refletirFechamento} onChange={handleRefletir} />}
                        />
                      </Typography>
                    </div>

                    <div style={{ height: 16 }}></div>

                    <Button variant="contained" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizaDespesa()}>Salvar</Button>

                    {despesaSelecionada !== 0 && <div style={{ height: 16 }}></div>}
                    {despesaSelecionada !== 0 && <Button variant="contained" color="error" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleRemoverDespesa()}>Deletar</Button>}

                    <div style={{ height: 24 }}></div>

                  </Grid>

                  {isMobile &&
                    <SwipeableDrawer
                      container={container}
                      anchor="bottom"
                      open={despesaSelecionada !== -1}
                      onClose={() => setDespesaSelecionada(-1)}
                      disableSwipeToOpen={true}
                      disableDiscovery={true}
                      ModalProps={{
                        keepMounted: true,
                        style: { zIndex: "10000" }
                      }}
                    >
                      <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} style={{ padding: 16, overflowX: "hidden", overflowY: "auto" }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{despesaSelecionada.NM_FNC ? despesaSelecionada.NM_FNC : "Novo lançamento"}
                          <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setDespesaSelecionada(-1)}>
                            <Close />
                          </IconButton>
                        </Typography>
                        <div style={{ height: 10 }} />
                        <Divider style={{ width: "120%", marginLeft: -16 }} />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Data do lançamento <b style={{ color: "#A51D1D" }}>*</b></Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                          <DatePicker
                            value={dataDespesa}
                            onChange={(newValue) => setDataDespesa(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Categoria
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          value={categoriaDespesa}
                          onChange={handleCategoria}
                          variant="outlined"
                          size="large"
                          placeholder="Insira uma categoria (Ifood, Fornecedor, Outros)"
                          type="text"
                        />

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Nome <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          value={nomeDespesa}
                          onChange={handleNome}
                          variant="outlined"
                          size="large"
                          placeholder="Insira o nome do fornecedor ou cliente"
                          type="text"
                        />

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Descrição
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          value={comentarioDespesa}
                          onChange={handleComentario}
                          variant="outlined"
                          size="large"
                          placeholder="Insira uma descrição"
                          type="text"
                        />

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Valor <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          value={valorDespesa}
                          onChange={handleValor}
                          variant="outlined"
                          size="large"
                          placeholder="Insira o valor"
                          type="text"
                        />

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Forma de pagamento <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={formaDePagamento}
                            onChange={handleFormaPagamento}
                          >
                            <MenuItem value={"crédito"}>Crédito</MenuItem>
                            <MenuItem value={"débito"}>Débito</MenuItem>
                            <MenuItem value={"pix"}>Pix</MenuItem>
                            <MenuItem value={"dinheiro"}>Dinheiro</MenuItem>
                            <MenuItem value={"outro"}>Outro</MenuItem>

                          </Select>
                        </FormControl>

                        <div style={{ height: 16 }}></div>

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Tipo <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={tipoDespesa}
                            onChange={handleTipoDespesa}
                          >
                            <MenuItem value={"compra"}>Compra</MenuItem>
                            <MenuItem value={"venda"}>Venda</MenuItem>
                          </Select>
                        </FormControl>

                        <div style={{ height: 16 }}></div>

                        <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                          <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600" }}>
                            <b>Refletir no fechamento?<br /><b style={{ fontSize: 14, fontWeight: "400" }}>Marque essa opção se deve refletir na contagem do fechamento</b></b>
                            <FormControlLabel style={{ marginLeft: "auto" }}
                              control={<MaterialUISwitch sx={{ m: 1 }} checked={refletirFechamento} onChange={handleRefletir} />}
                            />
                          </Typography>
                        </div>

                        <div style={{ height: 16 }}></div>

                        <Button variant="contained" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizaDespesa()}>Salvar</Button>

                        {despesaSelecionada !== 0 && <div style={{ height: 16 }}></div>}
                        {despesaSelecionada !== 0 && <Button variant="contained" color="error" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleRemoverDespesa()}>Deletar</Button>}

                        <div style={{ height: 24 }}></div>
                      </div>
                    </SwipeableDrawer>
                  }
                </>
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Despesas;