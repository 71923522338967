import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { Alert, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, formatarData, returnDia, toReal } from '../../functions/utils';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import { ArticleOutlined, RestaurantOutlined, QrCodeOutlined, ContentPaste, FormatPaintOutlined, ArrowForwardIosOutlined, StoreOutlined, InventoryOutlined, CalculateOutlined } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Home({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const [statusLoja, setStatusLoja] = useState("aberto");
  const [loading, setLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [alertStatus, setAlertStatus] = useState(true);
  const [aceitarTermos, setAceitarTermos] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [totalFaturamento, setTotalFaturamento] = useState("0");
  const [diasSemana, setDiasSemana] = useState([]);
  const [grafico, setGrafico] = useState([0, 0, 0, 0, 0, 0, 0]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleAcceptTerms = () => {
    handleOpenLoading()
    setIsFirst(false);
    const urlbase = Cookies.get('UBs');
    const post_url = urlbase + "/aceitar-termos.php";
    const json = JSON.stringify({
    });
    axios.post(post_url, json)
      .then(resp => {
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
    Cookies.set('FTs', "0", { expires: 7 });
  };

  useEffect(() => {
    handleOpenLoading()
    setIsFirst(Cookies.get("FTs") === "1")
    Cookies.get("sTA") && Cookies.get("sTA").includes("Fechado") ? setStatusLoja("fechado") : setStatusLoja("aberto")

    const dataAtual = new Date();
    dataAtual.setHours(23, 59, 59, 999);

    const diaDaSemanaAtual = dataAtual.getDay();
    const diasParaDomingo = diaDaSemanaAtual;

    const domingoAtual = new Date(dataAtual.getTime());
    domingoAtual.setDate(dataAtual.getDate() - diasParaDomingo);
    domingoAtual.setHours(0, 0, 0, 0);

    const diasParaSabado = 6 - diaDaSemanaAtual;

    const sabadoAtual = new Date(dataAtual.getTime());
    sabadoAtual.setDate(dataAtual.getDate() + diasParaSabado);
    sabadoAtual.setHours(23, 59, 59, 999);

    setDataInicial(formatarData(domingoAtual));
    setDataFinal(formatarData(sabadoAtual));

    const timestampDomingoAtual = parseInt(domingoAtual.getTime() / 1000);
    const timestampSabadoAtual = parseInt(sabadoAtual.getTime() / 1000) - 86400;
    getDashboard(timestampDomingoAtual, timestampSabadoAtual);

  }, []);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isFirst) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isFirst]);

  const handleStatus = (event) => {
    setStatusLoja(event.target.value)
  }

  const getDashboard = (dataInicial, dataFinal) => {
    handleOpenLoading()
    const idestabelecimento = Cookies.get("Md");
    const urlbase = Cookies.get("UBs");
    axios.get(`${urlbase}/dash-pdv-v2.php?idestabelecimentos=${idestabelecimento}&datainicial=${dataInicial}&datafinal=${dataFinal + 86399}`)
      .then(response => {
        response = response.data
        if (!response) {
          handleCloseLoading()
          return
        }

        setTotalFaturamento(parseFloat(response.faturamentoEntrega) + parseFloat(response.faturamentoMesa) + parseFloat(response.faturamentoRetirada))

        const pedidos7 = parseInt(response.retirada7) + parseInt(response.mesa7) + parseInt(response.entrega7)
        const pedidos6 = parseInt(response.retirada6) + parseInt(response.mesa6) + parseInt(response.entrega6)
        const pedidos5 = parseInt(response.retirada5) + parseInt(response.mesa5) + parseInt(response.entrega5)
        const pedidos4 = parseInt(response.retirada4) + parseInt(response.mesa4) + parseInt(response.entrega4)
        const pedidos3 = parseInt(response.retirada3) + parseInt(response.mesa3) + parseInt(response.entrega3)
        const pedidos2 = parseInt(response.retirada2) + parseInt(response.mesa2) + parseInt(response.entrega2)
        const pedidos1 = parseInt(response.retirada1) + parseInt(response.mesa1) + parseInt(response.entrega1)

        setGrafico([pedidos7, pedidos6, pedidos5, pedidos4, pedidos3, pedidos2, pedidos1])

        setDiasSemana([returnDia(response.diasemana7), returnDia(response.diasemana6), returnDia(response.diasemana5), returnDia(response.diasemana4), returnDia(response.diasemana3), returnDia(response.diasemana2), returnDia(response.diasemana1)])
        handleCloseLoading()
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />
      <Dialog
        open={isFirst}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <ContentPaste /> &nbsp;
          Termo de contratação
          {/* <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseTerms}>
            <CloseOutlined />
          </IconButton> */}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <b>TERMO DE ADESÃO - MELY</b><br /><br />
            <b>CONTRATADO (A):</b>
            As partes identificadas na seção de identificação deste contrato, sendo o CONTRATANTE,
            que solicita os serviços, e o CONTRATADO(A), que oferece os serviços de tecnologia, têm
            entre si justo e acertado o presente Contrato de Prestação de Serviços de Tecnologia. Este
            contrato é regido pelas disposições do Código Civil Brasileiro, especificamente nos artigos
            421, 422, 425, 594 e 598 do Código Civil Brasileiro, bem como no princípio do pacta sunt
            servanda e na Lei 13.429/17 (Lei da Terceirização), pelas cláusulas, condições de preço,
            forma e termo de pagamento descritas no presente.<br /><br />
            <b>DO OBJETO DO CONTRATO:</b><br /><br />
            <b>CLÁUSULA 1ª.</b> Constitui objeto do presente contrato a prestação de serviços de tecnologia,
            sem exclusividade pelo profissional prestador, cujo atendimento será realizado na sede da
            contratante somente quando necessário e indispensável o seu comparecimento, podendo
            este prestá-lo em qualquer lugar contanto que atingida a finalidade deste contrato, podendo,
            inclusive, se dar como home office.<br /><br />
            <b>DAS OBRIGAÇÕES DO (A) CONTRATADO (A)</b><br /><br />
            <b>CLÁUSULA 2ª.</b> São deveres do (a) CONTRATADO (A):
            Cumprir integralmente o disposto neste contrato;
            Utilizar técnicas condizentes com os serviços, efetuando todos os esforços para a sua
            consecução;
            Prestar contas, quando julgar necessário, à CONTRATANTE sobre suas atividades
            realizadas;<br /><br />
            <b>DAS OBRIGAÇÕES DA CONTRATANTE</b><br /><br />
            <b>CLÁUSULA 3ª.</b> São deveres da CONTRATANTE:
            Realizar o pagamento, conforme disposto na cláusula 5ª deste contrato;
            Zelar para que a CONTRATADA tenha à sua disposição todas as informações necessárias
            para as elaborações dos materiais que serão veiculados na ferramenta, e demais utilizados
            para obtenção do êxito da publicidade;
            Fica vedado à CONTRATANTE negociar abatimentos, descontos ou dilações de prazo para
            o pagamento ou execução dos serviços, sem o prévio conhecimento e autorização da
            contratada;
            Tratar o profissional com respeito, discrição, profissionalismo, lhe dando todo o suporte e
            liberdade dispostos nesse instrumento contratual;
            Informar previamente à CONTRATADA sobre toda e qualquer anormalidade que possa
            influir no sistema.<br /><br />
            <b>DO PAGAMENTO DOS SERVIÇOS</b><br /><br />
            <b>CLÁUSULA 4ª.</b> Pelo desempenho das atividades dispostas no contrato, a CONTRATADA
            receberá o valor condizente com o plano contratado, podendo ser:<br />
            <b>Plano Básico</b> de R$ 24,90 (vinte e quatro reais e noventa centavos) mensais referente aos
            serviços prestados.<br />
            <b>Plano Padrão</b> de R$ 29,90 (vinte nove reais e noventa centavos) mensais referente aos
            serviços prestados.<br />
            <b>Plano Profissional</b> de R$ 59,90 (cinquenta e nove reais e noventa centavos) mensais
            referente aos serviços prestados.<br />
            <b>PARÁGRAFO 1º:</b> O pagamento do valor acordado se dará através de PIX, TED ou até
            mesmo BOLETO até o dia do início da ativação do serviço de cada mês.<br /><br />
            <b>DA DURAÇÃO DO CONTRATO</b><br /><br />
            <b>CLÁUSULA 5ª.</b> O presente Contrato tem duração de 3 (três) meses, prorrogáveis
            automaticamente por período de 30 dias, sendo que, caso uma das partes não tenha
            interesse na prorrogação, deverá informar a outra parte com antecedência mínima de 30
            dias da prorrogação.<br />
            <b>Parágrafo único:</b> O presente Contrato poderá ser renovado até um prazo máximo de 2
            anos, passando após tal período para Contrato por tempo indeterminado, sendo
            desnecessária sua renovação anual, conforme preceitua a Lei n.º 9.601/98.<br /><br />
            <b>DA RESCISÃO:</b><br /><br />
            <b>Cláusula 6ª.</b> A violação de qualquer cláusula disposta neste presente instrumento rescinde
            automaticamente o presente instrumento, sendo imposta multa contratual no valor de 100%
            do período remanescente, em benefício da parte inocente, sem prejuízo de perdas e danos,
            bem como custos gerados na cobrança, se forem necessários, independente do lapso
            temporal do contrato firmado a parte que deu causa.<br /><br />
            <b>DA RESILIÇÃO:</b><br /><br />
            <b>Cláusula 7ª.</b> As partes poderão requerer a resilição do contrato a qualquer tempo, sem
            necessidade de pagamento de multas, desde que comunique sua vontade à outra parte
            com antecedência mínima de 20 (vinte) dias e desde que tenha cumprido o período de
            fidelidade de 03 meses. E o descumprimento poderá gerar uma multa rescisória equivalente
            ao valor total do contrato, além de custos extras decorrentes da quebra de contrato. E o não
            pagamento poderá acarretar inclusão aos órgãos de proteção ao crédito.<br />
            <b>Cláusula 8ª:</b> As partes concordam que, após rescisão contratual, independente da
            motivação, nenhuma das partes poderá utilizar a imagem da outra parte, seja pessoal, da
            marca, do estabelecimento, e afins, para fins publicitários, após a rescisão
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormGroup style={{ marginRight: "auto", marginLeft: 16 }}>
            <FormControlLabel control={<Checkbox checked={aceitarTermos} onChange={() => setAceitarTermos(!aceitarTermos)} />} label={<Typography style={{ fontSize: 14 }}>&nbsp;&nbsp;&nbsp;Aceitar os termos acima</Typography>} />
          </FormGroup>
          {/* <Button onClick={handleCloseTerms} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button> */}
          <Button onClick={handleAcceptTerms} variant="contained" size="large" disabled={!aceitarTermos} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }}>Finalizar</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={8}>
            {!isMobile &&
              <>
                <div style={{ height: "30px" }}></div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Atalhos</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <ArticleOutlined style={{ marginRight: 14, color: "#464545" }} />Pedidos
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Novos pedidos<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Em andamento e finalizados<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Relatórios<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "pedidos")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <RestaurantOutlined style={{ marginRight: 14, color: "#464545" }} /> Cardápio
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Cadastrar itens<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Gerenciamento<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Adicionar complementos<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "cardapio")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <InventoryOutlined style={{ marginRight: 14, color: "#464545" }} /> Estoque
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Gerenciamento de estoque<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Ativar e desativar itens<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Notificações de estoque<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "inventario")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <QrCodeOutlined style={{ marginRight: 14, color: "#464545" }} /> QR Code
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Adicionar mesas<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Gerenciamento de QR<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Impressão de QR<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "qrcode")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <FormatPaintOutlined style={{ marginRight: 14, color: "#464545" }} /> Personalizar
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Alterar cor padrão<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Alterar capa da loja<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Alterar banner promocional<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "personalizar")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardContent>
                        <Typography style={{ fontWeight: "bold", display: "flex" }}>
                          <CalculateOutlined style={{ marginRight: 14, color: "#464545" }} /> Fluxo
                          <label style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, width: 70, marginLeft: "auto", fontWeight: "600", maxHeight: 24 }}>NOVO</label>
                        </Typography>
                        <div style={{ height: "16px" }} />
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Gerenciamento de despesas<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Controle de fechamento<br />
                        </Typography>
                        <Typography style={{ fontSize: 14, color: "#999", display: "flex", alignItems: "center" }}>
                          <b style={{ fontSize: 10, marginTop: -2 }}>●</b> &nbsp; Controle financeiro<br />
                        </Typography>
                        <div style={{ height: "8px" }} />
                        <Button onClick={() => onChange("pg", "despesas")} style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", marginBottom: -16 }} endIcon={<ArrowForwardIosOutlined />}>
                          Acessar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        color: "#464545", fontSize: 14,
                        background: "#ECF0F9"
                      }}
                    >
                      <div>
                        <b style={{ fontSize: 16 }}>👋 Olá! Estamos de cara nova por aqui.</b><br />
                        Criamos um sistema novinho, focado em melhorar sua experiência. Que tal começar a usar? 🥳
                      </div>
                    </Alert>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {isMobile &&
              <Alert
                variant="outlined"
                severity="info"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  color: "#464545", fontSize: 14,
                  background: "#ECF0F9"
                }}
              >
                <div>
                  <b style={{ fontSize: 16 }}>👋 Olá! Estamos de cara nova por aqui.</b><br />
                  Criamos um sistema novinho, focado em melhorar sua experiência. Que tal começar a usar? 🥳
                </div>
              </Alert>
            }
            <div style={{ height: "30px" }} />
            <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Vendas</Typography>

            <div style={{ height: 16 }}></div>
            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardHeader
                title={
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <strong style={{ fontSize: 16, color: "#999999" }}>R$</strong>
                    <strong style={{ fontSize: 22, color: "#464545" }}> {toReal(totalFaturamento)}</strong>


                    <strong style={{ fontSize: 16, color: "#999999", marginLeft: "auto" }}> Ganhos (Semanal)</strong>
                    {/* <strong style={{ fontSize: 22, color: "#464545" }}>
                      <IconButton size="large" color="inherit" onClick={handleConfigure}>
                        <SettingsOutlined />
                      </IconButton>
                    </strong> */}
                  </Typography>
                }
                style={{ color: "#33540E" }}
              />

              <Divider />
              <CardContent>
                <BarChart
                  xAxis={[{ scaleType: 'band', data: diasSemana }]}
                  series={[{ data: grafico }]}
                  height={200}
                  margin={{ top: 0, left: 0, right: 0, bottom: 20 }}
                  leftAxis={null}
                  slotProps={{
                    bar: {
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },

                  }}
                  bottomAxis={{
                    disableLine: true,
                    disableTicks: true,
                  }}
                />

              </CardContent>
              <div style={{ height: "16px" }}></div>
            </Card>

            <div style={{ height: 30 }}></div>
            <Typography style={{ display: "flex", justifyContent: "left", alignItems: "center", fontWeight: "600", fontSize: 15, color: "#464545", background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
              <StoreOutlined /> &nbsp; Loja:
              <div style={{ width: 10 }}></div>
              <FormControl style={{ width: 150 }}>
                <Select
                  value={statusLoja}
                  placeholder="Status"
                  onChange={handleStatus}
                  size='small'
                  color="secondary"
                  disabled
                  style={{ color: statusLoja === "aberto" ? "green" : "tomato", fontWeight: "500", background: "#fff", fontSize: 14 }}
                >
                  <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                  <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                </Select>
              </FormControl>
              {/* <div style={{ width: 10 }}></div>
              <IconButton size="large" color="inherit" style={{ color: "#464545" }} onClick={handleConfigure}>
                <SettingsOutlined />
              </IconButton> */}
            </Typography>

            <div style={{ height: 20 }}></div>

            {alertStatus &&
              <Alert
                variant="outlined"
                severity="info"
                onClose={() => { setAlertStatus(false) }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  color: "#464545", fontSize: 14,
                  background: "#ECF0F9"
                }}
              >
                <div>
                  <b style={{ fontSize: 16 }}>Status da loja</b><br />
                  Altere ou configure o status da sua loja a qualquer momento
                </div>
              </Alert>
            }
          </Grid>
        </Grid>
      </div>

    </ThemeProvider>
  );
}

export default Home;