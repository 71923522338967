import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { CloseOutlined, DownloadOutlined, DragIndicator, KeyboardArrowRight } from '@mui/icons-material';

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import logoImage from "../../imagens/logo-mely-black.png";

function QRCodeScreen({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const captureRef = useRef();

  const [qrCodes, setQrs] = useState([]);
  const [qrCode, setQr] = useState(0);
  const [openModalQr, setOpenModalQr] = useState(false);
  const [mesa, setMesa] = useState("");
  const [modelo, setModelo] = useState(1);
  const [urlBase, setUrlBase] = useState("");

  const handleQr = (event, newValue) => {
    setQr(newValue);
    setOpenModalQr(true)
  };

  const handleNewQr = (event) => {
    setQr(0);
    setOpenModalQr(true)
  };

  const handleMesa = (event) => {
    setMesa(event.target.value);
  };

  const handleModelo = (event) => {
    setModelo(event.target.value);
  };

  const handleCloseModalQr = () => {
    setOpenModalQr(false)
  };

  useEffect(() => {
    const qrCodes = Cookies.get("MsS")
    if (qrCodes) {
      setQrs(qrCodes.split(","))
    }
    setUrlBase(Cookies.get("UBs").replaceAll("/api", ""))
  }, []);

  const addMesa = () => {
    if (mesa && mesa > 0) {
      let mesasAtuais = Cookies.get('MsS');

      if (!mesasAtuais) {
        mesasAtuais = '';
      }

      let mesasArray = mesasAtuais.split(',').filter(Boolean);

      if (!mesasArray.includes(mesa.toString())) {
        mesasArray.push(mesa);

        Cookies.set('MsS', mesasArray.join(','), { expires: 7 });
        handleCloseModalQr();
        setQrs(mesasArray);
        update("", `${Date.now()}___success___Mesa adicionada com sucesso.`)
      } else {
        handleCloseModalQr();
        update("", `${Date.now()}___error___A mesa já foi adicionada.`)
      }
    } else {
      handleCloseModalQr();
      update("", `${Date.now()}___error___O número da mesa está inválido.`)
    }
  }

  const excluirMesa = () => {
    const existingNumbers = Cookies.get('MsS');
    if (existingNumbers) {
      let mesasArray = existingNumbers.split(',');
      mesasArray = mesasArray.filter((num) => num !== qrCode.toString());
      Cookies.set('MsS', mesasArray.join(','), { expires: 7 });
      handleCloseModalQr();
      setQrs(mesasArray);
      update("", `${Date.now()}___success___Mesa excluída com sucesso.`)
    }
  };

  const handleCaptureClick = async () => {
    const element = captureRef.current;
    const canvas = await html2canvas(element);
    const dataURL = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'captured-image.png';
    link.click();
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Dialog
        open={openModalQr}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={isMobile}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          {qrCode === 0 ? <>Adicionar QR Code</> : qrCode === -1 ? <>Meu QR Code</> : <>Mesa {qrCode}</>}
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalQr}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>
          {qrCode === 0 &&
            <Box>
              <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                Mesa <b style={{ color: "#A51D1D" }}>*</b>
              </Typography>
              <div style={{ height: 4 }}></div>
              <TextField
                fullWidth
                value={mesa}
                onChange={handleMesa}
                variant="outlined"
                size="large"
                placeholder="Digite o numero da mesa"
                type="text"
              />
            </Box>
          }
          {qrCode === 0 && <div style={{ height: 20, }}></div>}
          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            Modelo <b style={{ color: "#A51D1D" }}>*</b>
          </Typography>
          <FormControl fullWidth>
            <Select
              value={modelo}
              onChange={handleModelo}
            >
              <MenuItem value={1}>Modelo 1</MenuItem>
              <MenuItem value={2}>Modelo 2</MenuItem>
            </Select>
          </FormControl>
          <>
            <div style={{ height: 20, }}></div>
            <div ref={captureRef}>
              <Box style={{ width: isMobile ? "100%" : 400, backgroundColor: modelo === 1 ? "#F2F2F2" : `rgba(${colorPrimary})`, paddingTop: 40, paddingBottom: 40 }}>
                {modelo === 2 &&
                  <>
                    <Typography style={{ textAlign: "center", color: "white", fontSize: 28 }}>ACESSE NOSSO<br /><b style={{ fontSize: 24 }}>QR CODE</b></Typography>
                    <div style={{ height: 20 }} />
                  </>
                }
                <div style={{ position: 'relative', width: 228, height: 228, background: "white", padding: 10, borderRadius: 8, margin: "auto" }}>
                  <QRCode value={`${urlBase}${qrCode === -1 ? "" : `?nm=${qrCode === 0 ? btoa(mesa) : btoa(qrCode)}`}`} size={208} />
                  <img
                    src={`${urlBase}/imagens/logo-store.png?${new Date().getTime()}`}
                    alt="Logo"
                    style={{
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                {qrCode !== -1 &&
                  <>
                    <div style={{ height: 2 }} />
                    <div style={{ background: "white", width: 70, padding: 3, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, margin: "auto", fontSize: 30, textAlign: "center", fontWeight: "bold" }}>
                      {qrCode === 0 ? mesa : qrCode}
                    </div>
                  </>
                }
                {modelo === 2 &&
                  <>
                    <div style={{ height: 20 }} />
                    <Typography style={{ textAlign: "center", color: "white", fontSize: 16 }}>OU ACESSE<br /><b style={{ fontSize: 14 }}>{`${urlBase}${qrCode === -1 ? "" : `?nm=${qrCode === 0 ? btoa(mesa) : btoa(qrCode)}`}`}</b></Typography>
                  </>
                }
              </Box>
              {modelo === 2 &&
                <>
                  <div style={{ height: 20 }} />
                  <Typography style={{ textAlign: "center", color: "#333", fontSize: 22, fontWeight: "bold" }}>{Cookies.get("Mn")}</Typography>
                  <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#333", fontSize: 17, fontWeight: "bold" }}><img src={logoImage} style={{ width: 80 }} /></Typography>
                  <div style={{ height: 20 }} />
                </>
              }
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button color="error" onClick={excluirMesa} size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Excluir</Button>
              <Button onClick={handleCaptureClick} size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }} startIcon={<DownloadOutlined />}>Baixar</Button>
            </div>
          </>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseModalQr} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          {qrCode === 0 && <Button onClick={addMesa} variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }}>Adicionar</Button>}
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <div style={{ height: "22px" }} />

        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>QR Code</Typography>
              </Grid>
              <Button onClick={handleNewQr} variant="contained" size="small" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", position: "absolute", top: 36, right: 24 }}>Adicionar</Button>

              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={0}>
                  {qrCodes.length === 0 &&
                    <>
                      <List>
                        <ListItem disablePadding style={{ backgroundColor: "" }} onClick={(event) => handleQr(event, -1)}>
                          <ListItemButton>
                            <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;Meu QR Code <KeyboardArrowRight style={{ marginLeft: "auto" }} /> </Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </List>
                    </>
                  }
                  <Grid item xs={12} sm={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <List>
                          {qrCodes.length > 0 &&
                            <List>
                              <ListItem disablePadding style={{ backgroundColor: "" }} onClick={(event) => handleQr(event, -1)}>
                                <ListItemButton>
                                  <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;Meu QR Code <KeyboardArrowRight style={{ marginLeft: "auto" }} /> </Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                                </ListItemButton>
                              </ListItem>
                              <Divider />
                            </List>
                          }
                          {qrCodes.map((qr) => (
                            <>
                              <ListItem disablePadding style={{ backgroundColor: qr === qrCode ? "#F2F2F2" : "" }} onClick={(event) => handleQr(event, qr)}>
                                <ListItemButton>
                                  <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;Mesa {qr} <KeyboardArrowRight style={{ marginLeft: "auto" }} /> </Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                                </ListItemButton>
                              </ListItem>
                              <Divider />
                            </>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default QRCodeScreen;