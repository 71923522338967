import React, { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Redirect() {

  const navigate = useNavigate();
  useEffect(() => {

    let urlBase = window.location.href.replaceAll("/estabelecimento", "");
    if (urlBase.includes("localhost")) {
      urlBase = "https://dedicado.mely.online/";
    }
    getConfig(urlBase);
    getColors()

    if(Cookies.get('isLogged')) {
      navigate("/");
    }

    if(Cookies.get('isLogged') && Cookies.get('isStore')) {
      navigate("/estabelecimento/home", { replace: true, state: { page: "home" } });
    }

    if(!Cookies.get('isLogged')) {
      navigate("/estabelecimento/login", { replace: true, state: { page: "home" } });
    }

  }, []);

  const getConfig = (url) => {
    axios.get(`${url}/api/get-conf.php`, {})
      .then(response => {
        const arrConf = response.data.split(`\n`)
        for (const variable of arrConf) {
          if (variable.includes("mainName")) {
            const mainName = variable.replace("const mainName = ", "").replaceAll("\"", "")
            document.title = mainName;
            Cookies.set('Mn', mainName, { expires: 7 });
          }
          if (variable.includes("timeDelivery")) {
            const timeDelivery = variable.replace("const timeDelivery = ", "").replaceAll("\"", "")
            const arrTime = timeDelivery.split("(")
            Cookies.set('tD', timeDelivery, { expires: 7 });
          }
          if (variable.includes("mainId")) {
            const mainId = variable.replace("const mainId = ", "")
            getInfos(url, mainId)
            getHorarios(url, mainId);
            Cookies.set('Md', mainId, { expires: 7 });
          }
          if (variable.includes("urlBase")) {
            const urlBase = variable.replace("const urlBase = ", "").replaceAll("\"", "")
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;
            Cookies.set('UBs', urlBase.includes("/api") ? urlBase : `${urlBase}/api`, { expires: 7 });
          }
          if (variable.includes("imgBannerPromo")) {
            const bannerPromo = variable.replace("const imgBannerPromo = ", "").replaceAll("\"", "")
            Cookies.set('BnPM', bannerPromo, { expires: 7 });
          }
          if (variable.includes("urlMessage")) {
            const urlMessage = variable.replace("const urlMessage = ", "").replaceAll("\"", "").replaceAll(" // wa.me = whatsapp ------ m.me = messenger", "")
            Cookies.set('UMs', urlMessage, { expires: 7 });
          }
          if (variable.includes("valorMinimo")) {
            const valorMinimo = variable.replace("const valorMinimo = ", "").replaceAll("\"", "")
            Cookies.set('vlMnm', valorMinimo, { expires: 7 });
          }
          if (variable.includes("enableStore")) {
            const enableStore = variable.replace("const enableStore = ", "")
            Cookies.set('eNbS', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("enableForBuy")) {
            const enableStore = variable.replace("const enableForBuy = ", "")
            Cookies.set('eNbB', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("onlyMenu")) {
            const enableStore = variable.replace("const onlyMenu = ", "")
            Cookies.set('eNbM', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("const imgBanner = ")) {
            const background = variable.replace("const imgBanner = ", "").replaceAll("\"", "").replaceAll("-banner", "")
            Cookies.set('BnIG', background, { expires: 7 });
          }
          if (variable.includes("const typeStore")) {
            const tipoLoja = variable.replace("const typeStore = ", "").replaceAll("\"", "")
            Cookies.set('tpLj', tipoLoja, { expires: 7 });
          }
        }
        console.log("ALO")
      })
      .catch(error => console.log("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getInfos = (url, mainId) => {
    axios.post(`${url}/api/avaliacoes.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const avaliacoes = response.data
        let notaTotal = 0, countNotas = 0
        if (avaliacoes) {
          for (const avaliacao of avaliacoes) {
            const partes = avaliacao.avaliacao.split(" -- ");
            notaTotal += parseInt(partes[0])
            countNotas++
          }
          notaTotal = notaTotal / countNotas
          notaTotal = notaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          Cookies.set('MnT', notaTotal, { expires: 7 });
          return
        }
        Cookies.set('MnT', "4,5", { expires: 7 });
      })
      .catch(error => console.log("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorarios = (url, mainId) => {
    axios.post(`${url}/api/horarios.php`, {
      idestabelecimentos: mainId,
    })
      .then(response => {
        response = response.data
        const horariosArray = response[0].horarios.split(",");
        const d = new Date();
        let diasemana
        if (d.getDay() == 0) {
          diasemana = horariosArray[6]
        } else {
          diasemana = horariosArray[d.getDay() - 1]
        }
        if (diasemana == "Fechado") {
          Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b>`, { expires: 7 });
        } else {
          var unix = Math.round(+new Date() / 1000);
          const horarios = diasemana.split(" às ");
          const horario1 = horarios[0].split(":");
          var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
          const horario2 = horarios[1].split(":");
          var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

          if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
            unix2 += 86400
          }

          const arrTime = Cookies.get("tD").split("(")
          const tempo = arrTime[1].replaceAll(")", "")

          if (unix > unix1 && unix < unix2) {
            Cookies.set('sTA', `<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`, { expires: 7 });
          } else {
            Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`, { expires: 7 });
          }
        }

      })
      .catch(error => console.log("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getColors = () => {
    let urlBase = window.location.href;

    if(urlBase.includes("?")) {
      let arrUrl = urlBase.split("?");
      urlBase = arrUrl[0];
    }

    if (urlBase.includes("localhost")) {
      urlBase = "https://dedicado.mely.online/";
    }
    axios.get((`${urlBase}/api/get-colors.php`).replaceAll("/estabelecimento", ""), {})
      .then(response => {
        const arrColors = response.data.split(`\n`)
        const colorPrimaryC = arrColors[1].replaceAll("    --primary: ", "").replaceAll(";", "")
        const colorSecondaryC = arrColors[2].replaceAll("    --secondary: ", "").replaceAll(";", "")
        const colorTertiaryC = arrColors[3].replaceAll("    --tertiary: ", "").replaceAll(";", "")
        console.log(arrColors)
        Cookies.set('clPmr', colorPrimaryC, { expires: 7 });
        Cookies.set('clScr', colorSecondaryC, { expires: 7 });
        Cookies.set('clTcr', colorTertiaryC, { expires: 7 });
        
      })
      .catch(error => console.error('Error:', error));
  }

  return (
    <Container>

   </Container>
  );
}

export default Redirect;