import { Alert, AppBar, Avatar, Box, Button, Divider, FormControl, IconButton, InputAdornment, ListItemButton, ListItemIcon, Menu, MenuItem, Modal, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { ArticleOutlined, CalculateOutlined, Close, CloudDownloadOutlined, ContactSupportOutlined, ContentCopy, CurrencyExchangeOutlined, DensityMediumOutlined, EditOutlined, FormatPaintOutlined, GroupsOutlined, HomeOutlined, InventoryOutlined, LayersOutlined, ListAltOutlined, LoginOutlined, MenuRounded, MopedOutlined, PointOfSaleOutlined, PrintOutlined, QrCodeOutlined, RestaurantOutlined, Search, SettingsOutlined, ShareOutlined, StoreOutlined, StorefrontOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { formatarHorarioCompleto, stringAvatar } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Cookies from 'js-cookie';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: 16,
    marginTop: -4,
    backgroundColor: "#fff",
}));

const SideMenu = ({ onChange, update, colorPrimary }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [statusLoja, setStatusLoja] = useState("aberto");
    const [openModal, setOpenModal] = useState(false);
    const [modalPdv, setModalPdv] = useState(false);
    const [isEstoqueMinimo, setIsEstoqueMinimo] = useState(false);
    const [currentPage, setCurrentPage] = useState("home");
    const [urlBase, setUrlBase] = useState("");
    const [tempoEntrega, setTempoEntrega] = useState("");
    const [alerts, setAlerts] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const [colorPrimaryS, setColorPrimary] = useState(false);
    const openPerfil = Boolean(anchorEl);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loadingPdv, setLoadingPdv] = useState(false);
    const [progress, setProgress] = useState(0);

    const [isRunning, setIsRunning] = useState(false);
    const intervalIdRef = useRef(null);

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        setModalPdv(false)
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleShare = () => {
        handleOpenModal()
    }

    const handleStatus = (event) => {
        setStatusLoja(event.target.value)
    }

    const changePage = (page) => {
        setOpen(false)
        onChange("", page);
        setCurrentPage(page)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const urlbase = Cookies.get('UBs');
        setUrlBase(urlbase)
        const timeDelivery = Cookies.get('tD');
        const arrTime = timeDelivery.split("(")
        setTempoEntrega(arrTime[1].replaceAll(")", ""))
        getHorarios(urlbase, "1")
        if (Cookies.get('eMA')) {
            setIsRunning(true);
        }
    }, [currentPage]);

    useEffect(() => {
        if (isRunning) {
            intervalIdRef.current = setInterval(() => {
                getProdutos();
            }, 10000);
        }

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [isRunning]);

    const getHorarios = (url, mainId) => {
        axios.post(`${url}/horarios.php`, {
            idestabelecimentos: mainId,
        })
            .then(response => {
                response = response.data
                const horariosArray = response[0].horarios.split(",");
                const d = new Date();
                let diasemana
                if (d.getDay() == 0) {
                    diasemana = horariosArray[6]
                } else {
                    diasemana = horariosArray[d.getDay() - 1]
                }
                if (diasemana == "Fechado") {
                    setStatusLoja("fechado")
                } else {
                    var unix = Math.round(+new Date() / 1000);
                    const horarios = diasemana.split(" às ");
                    const horario1 = horarios[0].split(":");
                    var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
                    const horario2 = horarios[1].split(":");
                    var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

                    if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
                        unix2 += 86400
                    }

                    const arrTime = Cookies.get("tD").split("(")
                    const tempo = arrTime[1].replaceAll(")", "")

                    if (unix > unix1 && unix < unix2) {
                        setStatusLoja("aberto")
                    } else {
                        setStatusLoja("fechado")
                    }
                }

            })
            .catch(error => console.log("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    }

    useEffect(() => {
        if (update.includes("PDV")) {
            setOpenModal(true)
            setModalPdv(true)
        } else if (update.includes("estoque")) {
            const arrClr = update.split("___")
            if (arrClr[2] === "true") {
                setIsRunning(true)
            }
            getProdutos()
        } else if (update.includes("STPG")) {
            const arrClr = update.split("___")
            setCurrentPage(arrClr[1])
        } else if (update.includes("UPDTCLP")) {
            const arrClr = update.split("___")
            setColorPrimary(arrClr[1])
        } else {
            const arrAlert = update.split("___")
            handleAlert(arrAlert[2], arrAlert[1])
        }
    }, [update]);

    const getProdutos = () => {
        const urlBase = Cookies.get('UBs');
        axios.post(`${urlBase}/produtos-inventario.php?idestabelecimentos=1`, {
            idestabelecimentos: "1"
        })
            .then(response => {
                response = response.data
                if (response) {
                    for (const produto of response) {
                        if (Cookies.get('eMA') === "true") {
                            const estoqueMinimo = parseInt(Cookies.get('eMAv'))
                            if (parseInt(produto.estoque) <= estoqueMinimo) {
                                setIsEstoqueMinimo(true)
                                return
                            }
                        } else {
                            setIsEstoqueMinimo(false)
                            setIsRunning(false)
                        }
                    }
                    setIsEstoqueMinimo(false)
                }
            })
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    setOpenModal(false);
                    handleAlert("Link copiado com sucesso.", "success")
                })
                .catch(err => {
                    handleAlert("Não foi possivel copiar o link", "error")
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                handleAlert("Link copiado com sucesso.", "success")
            } catch (err) {
                handleAlert("Não foi possivel copiar o link", "error")
            }
            document.body.removeChild(textArea);
        }
    }

    const handleAlert = (mensagem, tipo) => {
        if (mensagem) {
            const id = new Date().getTime();
            const newAlert = { id, mensagem, tipo };
            setAlerts(prevAlerts => [...prevAlerts, newAlert]);

            setTimeout(() => {
                setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
            }, 5000);
        }
    };

    const handleCloseAlert = (id) => {
        setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
    };

    const getColorAlert = (tipoAlerta) => {
        switch (tipoAlerta) {
            case "success":
                return "#33550F";
            case "info":
                return "#464545";
            case "warning":
                return "#464545";
            case "error":
                return "#333";

        }
    }

    const getBackgroundAlert = (tipoAlerta) => {
        switch (tipoAlerta) {
            case "success":
                return "#F4F9EC";
            case "info":
                return "#ECF0F9";
            case "warning":
                return "#fffae6";
            case "error":
                return "#FFE9E6";

        }
    }

    const handleCopy = () => {
        copyToClipboard(urlBase.replaceAll("/api", ""));
    };

    const handleOpenPerfil = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePerfil = () => {
        setAnchorEl(null);
    };

    const handleSuporte = () => {
        handleClosePerfil()
        changePage("suporte")
    };

    const handleConfiguracoes = () => {
        handleClosePerfil()
        changePage("configuracoes")
    };

    const handleModalPdv = () => {
        setOpenModal(true)
        setModalPdv(true)
    };

    const handleLogout = () => {
        Cookies.remove('dsEst');
        Cookies.remove('emEst');
        Cookies.remove('endEst');
        Cookies.remove('fMsP');
        Cookies.remove('hREst');
        Cookies.remove('iEst');
        Cookies.remove('nmEst');
        Cookies.remove('txEst');
        Cookies.remove('tEst');
        Cookies.remove('tsEst');
        Cookies.remove('UBs');
        Cookies.remove('FTs');
        Cookies.remove('isLogged');
        Cookies.remove('isStore');
        navigate("/estabelecimento/login", { replace: true, state: { page: "home" } });
    };

    const handleBaixarPDV = () => {
        setLoadingPdv(true);
        axios.post('https://mely.online/template/download-pdv.php', {
            idestabelecimentos: "1"
        }, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                const percentCompleted = Math.round((current / total) * 100);
                setProgress(percentCompleted);
            }
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `pdv-mely.zip`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setLoadingPdv(false);
            })
            .catch(error => {
                setLoadingPdv(false);
            });
    }

    return (
        <>
            <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimaryS})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 75, display: "flex", justifyContent: "center" }}>
                <Toolbar>

                    {isMobile ?
                        <>
                            <IconButton size="large" color="inherit" style={{ color: "#FFF" }} onClick={() => setOpen(true)}>
                                <MenuRounded style={{ fontSize: 42 }} />
                            </IconButton>
                            <IconButton size="large" color="inherit" style={{ color: "#FFF", marginLeft: "auto" }} onClick={handleShare}>
                                <ShareOutlined />
                            </IconButton>
                            <Button style={{ color: "white", textTransform: 'none', marginLeft: 6 }} startIcon={<StoreOutlined />}>
                                Loja&nbsp;&nbsp;<div style={{ borderRadius: "50%", background: statusLoja === "aberto" ? "#6B9F29" : "tomato", height: 14, width: 14, marginTop: -3 }} />
                            </Button>
                            <Button style={{ color: "white", textTransform: 'none', marginLeft: 6 }} startIcon={<MopedOutlined />}>
                                {tempoEntrega}
                            </Button>
                        </>
                        :
                        <>
                            {/* <TextField
                                color='secondary'
                                placeholder="Buscar"
                                sx={{ m: 1, width: '25ch', marginLeft: "315px" }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                                }}
                            /> */}


                            {/* <IconButton size="large" color="inherit" style={{ color: "#FFF" }} onClick={handleShare}>
                                <ShareOutlined />
                            </IconButton> */}
                            <Typography style={{ marginLeft: "auto" }}>{formatarHorarioCompleto(dataAtual)}</Typography>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ background: "#fff", width: 1, height: "60%", marginLeft: 10 }} />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip title={`Tempo de entrega: ${tempoEntrega}`}>
                                <Button style={{ color: "white", textTransform: 'none' }} startIcon={<MopedOutlined />}>
                                    {tempoEntrega}
                                </Button>
                            </Tooltip>

                            <Tooltip title={`Loja ${statusLoja === "aberto" ? "aberta" : "fechada"}`}>
                                <Button style={{ color: "white", textTransform: 'none', marginLeft: 16 }} startIcon={<StoreOutlined />}>
                                    Loja&nbsp;&nbsp;<div style={{ borderRadius: "50%", background: statusLoja === "aberto" ? "#6B9F29" : "tomato", height: 14, width: 14, marginTop: -3 }} />
                                </Button>
                            </Tooltip>

                            {/* <Button style={{ color: "white", textTransform: 'none', marginLeft: 16 }} startIcon={<PrintOutlined />}>
                                Impressora&nbsp;&nbsp;<div style={{ borderRadius: "50%", background: statusLoja === "aberto" ? "#6B9F29" : "tomato", height: 14, width: 14, marginTop: -3 }} />
                            </Button> */}

                            <div style={{ background: "#fff", width: 1, height: "60%", marginLeft: 10 }} />

                            <Tooltip title={`Compartilhar`}>
                                <IconButton size="large" color="inherit" style={{ color: "#FFF", marginLeft: 4 }} onClick={handleShare}>
                                    <ShareOutlined />
                                </IconButton>
                            </Tooltip>

                            {/* <IconButton size="large" color="inherit" style={{ color: "#FFF" }} onClick={handleShare}>
                                <VolumeUpOutlined />
                            </IconButton> */}

                            <Tooltip title={`Perfil`}>
                                <Avatar {...stringAvatar(Cookies.get("Mn"))} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer" }} onClick={handleOpenPerfil} />
                            </Tooltip>
                            <Menu id="perfil" anchorEl={anchorEl} open={openPerfil} onClose={handleClosePerfil}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar {...stringAvatar(Cookies.get("Mn"))} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer" }} />
                                    <div style={{ width: 10 }} />
                                    <Typography style={{ fontSize: 14, color: "#999" }}>
                                        <label style={{ fontSize: 16, color: "#1B1B1B" }}>{Cookies.get("Mn")}</label><br />
                                        {Cookies.get("emEst")}
                                    </Typography>
                                </div>

                                <div style={{ height: 10 }} />
                                <Divider />
                                <List style={{ width: 315, fontSize: 16 }}>
                                    <ListItem button onClick={handleSuporte}>
                                        <ContactSupportOutlined style={{ marginRight: 14, color: "#464545" }} />
                                        <ListItemText primary="Suporte" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                    </ListItem>
                                    <ListItem button onClick={handleConfiguracoes}>
                                        <SettingsOutlined style={{ marginRight: 14, color: "#464545" }} />
                                        <ListItemText primary="Configurações" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                    </ListItem>
                                    <ListItem button onClick={handleLogout}>
                                        <LoginOutlined style={{ marginRight: 14, color: "#464545" }} />
                                        <ListItemText primary="Sair" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                    </ListItem>
                                </List>
                            </Menu>
                            {/* <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Loja:
                                <div style={{ width: 10 }}></div>
                                <FormControl style={{ width: 150 }}>
                                    <Select
                                        value={statusLoja}
                                        placeholder="Status"
                                        onChange={handleStatus}
                                        color="secondary"
                                        style={{ color: statusLoja === "aberto" ? "green" : "tomato" }}
                                    >
                                        <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                                        <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ width: 10 }}></div>
                                <Avatar {...stringAvatar("Joelson Lucas")} style={{ margin: "auto", width: "38px", height: "38px", fontSize: 14 }} />

                            </Typography> */}
                        </>
                    }
                </Toolbar>
            </AppBar>

            {alerts.map(alert => (
                <Alert
                    key={alert.id}
                    variant="outlined"
                    severity={alert.tipo}
                    onClose={() => handleCloseAlert(alert.id)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        color: getColorAlert(alert.tipo), fontSize: 14,
                        background: getBackgroundAlert(alert.tipo),
                        position: "fixed",
                        top: 100 + (alerts.indexOf(alert) * 60), // Ajusta a posição dos alertas
                        right: 24,
                        zIndex: 10000
                    }}
                >
                    <div>
                        <b style={{ fontSize: 16 }}>{alert.mensagem}</b>
                    </div>
                </Alert>
            ))}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box sx={style} style={{ width: isMobile ? "100%" : "" }}>
                    <Typography style={{ fontSize: 18, color: "#464545", fontWeight: "bold", display: "flex", padding: 16 }}>
                        {modalPdv ?
                            <>
                                <CloudDownloadOutlined /> <div style={{ width: 10 }} /> Baixar PDV <Close style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleCloseModal} />
                            </>
                            :
                            <>
                                <ShareOutlined /> <div style={{ width: 10 }} /> Compartilhar <Close style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleCloseModal} />
                            </>
                        }
                    </Typography>

                    <Divider />

                    {modalPdv ?
                        <Typography style={{ fontSize: 16, padding: 16, fontWeight: "normal", maxHeight: "70vh", overflowY: "auto" }}>
                            <b>Ao baixar e utilizar nosso sistema de PDV, você reconhece e aceita os seguintes termos e condições:</b><br /><br />

                            <b>Uso do Sistema: </b>Nosso sistema de PDV é fornecido "como está" e deve ser utilizado apenas para fins comerciais legítimos.<br />

                            <b>Responsabilidade: </b>Não nos responsabilizamos por quaisquer danos ou perdas decorrentes do uso deste sistema de PDV.<br />

                            <b>Atualizações: </b>Podemos realizar atualizações e modificações no sistema de PDV a qualquer momento, visando melhorias e correções.<br />

                            <b>Privacidade: </b>Qualquer informação coletada através do uso do sistema será tratada conforme nossa política de privacidade.<br />

                            <b>Suporte: </b>Oferecemos suporte técnico conforme descrito em nosso site e materiais de apoio.<br /><br />

                            Agradecemos por escolher nosso sistema de PDV. <br />
                        </Typography>
                        :
                        <Typography style={{ fontSize: 16, padding: 16, fontWeight: "bold" }}>
                            Copie o link abaixo para enviar<br />
                            <Tooltip title={`Clique aqui para copiar o link`}>
                                <Typography style={{ width: "100%", border: "solid 1px #DCDBDB", padding: 10, borderRadius: "4px", cursor: "pointer" }} onClick={handleCopy}>{urlBase.replaceAll("/api", "")}<ContentCopy style={{ float: "right" }} /></Typography>
                            </Tooltip>
                        </Typography>
                    }

                    {modalPdv &&
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            <Divider />
                            <br />
                            <Button fullWidth size="large" variant="contained" style={{ textTransform: 'none' }} disabled={loadingPdv} onClick={handleBaixarPDV} startIcon={<CloudDownloadOutlined />}>
                                {loadingPdv ? `Baixando... ${progress}%` : 'Baixar PDV'}
                            </Button>
                            <br />
                            <br />
                        </div>
                    }

                </Box>
            </Modal>

            {isMobile ? <Drawer open={open} onClose={toggleDrawer(false)}>
                <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "bold" }}>
                    <img src="https://mely.online/imagens/mely-logo-white.png" style={{ width: 100, marginLeft: 20 }} />
                    &nbsp;&nbsp;&nbsp;v2.0.4.0
                </div>
                <List style={{ width: 315, padding: 20 }}>
                    <ListItem button style={{ background: currentPage === "home" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("home")}>
                        <HomeOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "home" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                </List>
                <div style={{ height: 10 }}></div>
                <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Geral</Typography>
                <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                    <ListItem button style={{ background: currentPage === "pedidos" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("pedidos")}>
                        <ArticleOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Pedidos" primaryTypographyProps={{ color: currentPage === "pedidos" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "cardapio" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("cardapio")}>
                        <RestaurantOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Cardápio" primaryTypographyProps={{ color: currentPage === "cardapio" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "inventario" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("inventario")}>
                        <InventoryOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Estoque" primaryTypographyProps={{ color: currentPage === "inventario" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        {isEstoqueMinimo &&
                            <>
                                <div style={{ borderRadius: "50%", background: "tomato", height: 14, width: 14, marginTop: -3 }} />
                            </>
                        }
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "qrcode" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("qrcode")}>
                        <QrCodeOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="QR Code" primaryTypographyProps={{ color: currentPage === "qrcode" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "personalizar" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("personalizar")}>
                        <FormatPaintOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Personalizar" primaryTypographyProps={{ color: currentPage === "personalizar" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "pdv" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => handleModalPdv()}>
                        <StorefrontOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="PDV" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                    </ListItem>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <CurrencyExchangeOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Gestão Financeira" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItem button style={{ background: currentPage === "despesas" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("despesas")}>
                                <CalculateOutlined style={{ marginRight: 14, color: "#464545" }} />
                                <ListItemText primary="Fluxo" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                                <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                            </ListItem>
                            <ListItem button style={{ background: currentPage === "fechamento" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("fechamento")}>
                                <PointOfSaleOutlined style={{ marginRight: 14, color: "#464545" }} />
                                <ListItemText primary="Fechamento" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                                <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                            </ListItem>
                        </AccordionDetails>
                    </Accordion>
                    <ListItem button style={{ background: currentPage === "clientes" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("clientes")}>
                        <GroupsOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Clientes" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                        <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                    </ListItem>
                </List>

                <div style={{ height: 20 }}></div>
                <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Outras funções</Typography>
                <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                    <ListItem button style={{ background: currentPage === "configuracoes" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("configuracoes")}>
                        <SettingsOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Configurações" primaryTypographyProps={{ color: currentPage === "configuracoes" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem button style={{ background: currentPage === "suporte" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("suporte")}>
                        <ContactSupportOutlined style={{ marginRight: 14, color: "#464545" }} />
                        <ListItemText primary="Suporte" primaryTypographyProps={{ color: currentPage === "suporte" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                    </ListItem>
                </List>
            </Drawer> :
                <Drawer
                    variant="permanent"
                    anchor="left"
                >
                    <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "bold" }}>
                        <img src="https://mely.online/imagens/mely-logo-white.png" style={{ width: 100, marginLeft: 20 }} />
                        &nbsp;&nbsp;&nbsp;v2.0.4.0
                    </div>
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "home" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("home")}>
                            <HomeOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "home" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                    </List>
                    <div style={{ height: 10 }}></div>
                    <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Geral</Typography>
                    <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                        <ListItem button style={{ background: currentPage === "pedidos" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("pedidos")}>
                            <ArticleOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Pedidos" primaryTypographyProps={{ color: currentPage === "pedidos" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "cardapio" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("cardapio")}>
                            <RestaurantOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Cardápio" primaryTypographyProps={{ color: currentPage === "cardapio" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "inventario" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("inventario")}>
                            <InventoryOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Estoque" primaryTypographyProps={{ color: currentPage === "inventario" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                            {isEstoqueMinimo &&
                                <>
                                    <div style={{ borderRadius: "50%", background: "tomato", height: 14, width: 14, marginTop: -3 }} />
                                </>
                            }
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "qrcode" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("qrcode")}>
                            <QrCodeOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="QR Code" primaryTypographyProps={{ color: currentPage === "qrcode" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "personalizar" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("personalizar")}>
                            <FormatPaintOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Personalizar" primaryTypographyProps={{ color: currentPage === "personalizar" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "pdv" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => handleModalPdv()}>
                            <StorefrontOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="PDV" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                        </ListItem>
                        <Accordion>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <CurrencyExchangeOutlined style={{ marginRight: 14, color: "#464545" }} />
                                <ListItemText primary="Gestão Financeira" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListItem button style={{ background: currentPage === "despesas" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("despesas")}>
                                    <CalculateOutlined style={{ marginRight: 14, color: "#464545" }} />
                                    <ListItemText primary="Fluxo" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                                    <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                                </ListItem>
                                <ListItem button style={{ background: currentPage === "fechamento" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("fechamento")}>
                                    <PointOfSaleOutlined style={{ marginRight: 14, color: "#464545" }} />
                                    <ListItemText primary="Fechamento" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                                    <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                                </ListItem>
                            </AccordionDetails>
                        </Accordion>

                        <ListItem button style={{ background: currentPage === "clientes" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("clientes")}>
                            <GroupsOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Clientes" primaryTypographyProps={{ color: currentPage === "pdv" ? "#F94A3D" : "#464545", fontWeight: "600" }} />
                            <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4, maxHeight: 24 }}>NOVO</Typography>
                        </ListItem>
                    </List>

                    <div style={{ height: 20 }}></div>
                    <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Outras funções</Typography>
                    <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
                        <ListItem button style={{ background: currentPage === "configuracoes" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("configuracoes")}>
                            <SettingsOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Configurações" primaryTypographyProps={{ color: currentPage === "configuracoes" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "suporte" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("suporte")}>
                            <ContactSupportOutlined style={{ marginRight: 14, color: "#464545" }} />
                            <ListItemText primary="Suporte" primaryTypographyProps={{ color: currentPage === "suporte" ? "#F94A3D" : "#464545", fontWeight: "bold" }} />
                        </ListItem>
                    </List>
                </Drawer>
            }


        </>
    );
};

export default SideMenu;
