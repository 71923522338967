import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, hexToRgba, rgbaToHex } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, FormControlLabel, Grid, Typography } from '@mui/material';
import { UploadOutlined } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import { MaterialUISwitch } from '../../components/utils';

function Personalizar({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);
  const fileInputRefPromo = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePromo, setSelectedImagePromo] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedImagePromoFile, setSelectedImagePromoFile] = useState(null);
  const [showPalletP, setShowPalletP] = useState(false);
  const [showPalletS, setShowPalletS] = useState(false);
  const [showPalletT, setShowPalletT] = useState(false);
  const [bannerOn, setBanner] = useState(false);
  const [bannerNormal, setBannerNormal] = useState(false);
  const [bannerPromocional, setBannerPromocional] = useState(false);
  const [colorPrimaryS, setColorPrimary] = useState(colorPrimary);
  const [colorSecondaryS, setColorSecondary] = useState(colorSecondary);
  const [colorTertiaryS, setColorTertiary] = useState(colorTertiary);
  const [color, setColor] = useState('#fff');
  const [urlBase, setUrlBase] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    if (showPalletP) {
      update("", `UPDTCLP___${hexToRgba(color.hex).replace("rgba(", "").replace(")", "")}`)
      setColorPrimary(hexToRgba(color.hex).replace("rgba(", "").replace(")", ""))
    }
    if (showPalletS) {
      setColorSecondary(hexToRgba(color.hex).replace("rgba(", "").replace(")", ""))
    }
    if (showPalletT) {
      setColorTertiary(hexToRgba(color.hex).replace("rgba(", "").replace(")", ""))
    }
  };

  const handleBanner = (event) => {
    setBanner(event.target.checked);
  };

  const handleOpenPrimary = () => {
    const arrPmr = colorPrimaryS
    setColor(rgbaToHex(arrPmr[0], arrPmr[1], arrPmr[2], 1));
    setShowPalletP(true);
    setShowPalletS(false);
    setShowPalletT(false);
  };

  const handleOpenSecondary = () => {
    const arrScr = colorSecondaryS
    setColor(rgbaToHex(arrScr[0], arrScr[1], arrScr[2], 1));
    setShowPalletS(true);
    setShowPalletP(false);
    setShowPalletT(false);
  };

  const handleOpenTertiary = () => {
    const arrTcr = colorTertiaryS
    setColor(rgbaToHex(arrTcr[0], arrTcr[1], arrTcr[2], 1));
    setShowPalletP(false);
    setShowPalletS(false);
    setShowPalletT(true);
  };

  useEffect(() => {
    setColorPrimary(Cookies.get("clPmr").split(", "))
    setColorSecondary(Cookies.get("clScr").split(", "))
    setColorTertiary(Cookies.get("clTcr").split(", "))
    setBannerNormal(Cookies.get("BnIG"))
    setBannerPromocional(Cookies.get("BnPM"))
    setBanner(Cookies.get("BnPM") ? true : false)
    setUrlBase(Cookies.get('UBs'))
    handleOpenPrimary()
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const validFileTypes = ['image/png', 'image/jpeg'];

      if (!validFileTypes.includes(fileType)) {
        update("", `${Date.now()}___warning___Somente arquivos PNG ou JPG são permitidos.`)
        setSelectedImage(null);
        setSelectedImageFile(null)
        return;
      }

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        update("", `${Date.now()}___warning___O arquivo deve ter no máximo 5MB.`)
        setSelectedImage(null);
        setSelectedImageFile(null)
        return;
      }

      const reader = new FileReader();
      setSelectedImageFile(file)
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedImage(null)
    setSelectedImageFile(null)
  };

  const handleFileChangePromo = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const validFileTypes = ['image/png', 'image/jpeg'];

      if (!validFileTypes.includes(fileType)) {
        update("", `${Date.now()}___warning___Somente arquivos PNG ou JPG são permitidos.`)
        setSelectedImagePromo(null);
        setSelectedImagePromoFile(null)
        return;
      }

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        update("", `${Date.now()}___warning___O arquivo deve ter no máximo 5MB.`)
        setSelectedImagePromo(null);
        setSelectedImagePromoFile(null)
        return;
      }

      const reader = new FileReader();
      setSelectedImagePromoFile(file)
      reader.onload = (e) => {
        setSelectedImagePromo(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadFilePromo = () => {
    fileInputRefPromo.current.click();
  };

  const handleRemoveFilePromo = () => {
    setSelectedImagePromo(null)
    setSelectedImagePromoFile(null)
  };

  const handleSalvarImagens = async (file, file2) => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');
    const post_url = `${urlBase}/atualizar-banner.php`;
    const formData = new FormData();
    file2 ? formData.append('banner', file2) : console.log("");
    file ? formData.append('banner-img', file) : console.log("");
    bannerOn ? formData.append('enablebanner', "enable") : console.log("");
    formData.append('inicial-img-banner', Cookies.get("BnIG"))
    try {
      const response = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data) {
        if (response.data.upload === "tamanho-b") {
          handleCloseLoading()
          update("", `${Date.now()}___error___A imagem do banner promocional selecionada é maior que 5MB.`)
          return
        }
        if (response.data.upload === "formato-b") {
          handleCloseLoading()
          update("", `${Date.now()}___error___O formato da imagem do banner promocional selecionada não é suportado.`)
          return
        }
        if (response.data.upload === "falha-b") {
          handleCloseLoading()
          update("", `${Date.now()}___error___Ocorreu uma falha ao atualizar a imagem do banner promocional selecionada.`)
          return
        }
        if (response.data.uploadB === "tamanho-a") {
          handleCloseLoading()
          update("", `${Date.now()}___error___A imagem do banner selecionada é maior que 5MB.`)
          return
        }
        if (response.data.uploadB === "formato-a") {
          handleCloseLoading()
          update("", `${Date.now()}___error___O formato da imagem do banner selecionada não é suportado.`)
          return
        }
        if (response.data.uploadB === "falha-a") {
          handleCloseLoading()
          update("", `${Date.now()}___error___Ocorreu uma falha ao atualizar a imagem do banner selecionada.`)
          return
        }
        if (response.data.resposta === "sucesso atualizar") {
          Cookies.set('BnIG', "background.png", { expires: 7 });
          Cookies.set('BnPM', bannerOn ? "customing" : "", { expires: 7 });
          handleCloseLoading()
          update("", `${Date.now()}___success___Seus banners foram atualizados com sucesso.`)
          return
        }
      }
      handleCloseLoading()
      update("", `${Date.now()}___error___Não foi possivel alterar seus banners. Por favor verifique sua conexão e tente novamente.`)
    } catch (error) {
      handleCloseLoading()
      update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
    }
  }

  const handleSalvar = () => {
    handleOpenLoading()
    setTimeout(() => {
      handleSalvarImagens(selectedImagePromoFile, selectedImageFile)
    }, 2000);
    
    if (!(Array.isArray(colorPrimaryS) && Array.isArray(colorSecondaryS) && Array.isArray(colorTertiaryS))) {
      let cor1, cor2, cor3
      Array.isArray(colorPrimaryS) ? cor1 = `${colorPrimaryS[0]}, ${colorPrimaryS[1]}, ${colorPrimaryS[2]}` : cor1 = colorPrimaryS.replace(/, 1$/, "");
      Array.isArray(colorSecondaryS) ? cor2 = `${colorSecondaryS[0]}, ${colorSecondaryS[1]}, ${colorSecondaryS[2]}` : cor2 = colorSecondaryS.replace(/, 1$/, "");
      Array.isArray(colorTertiaryS) ? cor3 = `${colorTertiaryS[0]}, ${colorTertiaryS[1]}, ${colorTertiaryS[2]}` : cor3 = colorTertiaryS.replace(/, 1$/, "");

      const newConf = `:root {
        --primary: ${cor1};
        --secondary: ${cor2};
        --tertiary: ${cor3};
    }`
    
      const urlBase = Cookies.get('UBs');
      const post_url = `${urlBase}/editar-cores.php`;
      const json = JSON.stringify({
        newConf: newConf,
      });

      axios.post(post_url, json)
        .then(resp => {
          const response = resp.data
          if (response) {
            if (response.resposta === "sucesso atualizar") {
              Cookies.set('clPmr', cor1, { expires: 7 });
              Cookies.set('clScr', cor2, { expires: 7 });
              Cookies.set('clTcr', cor3, { expires: 7 });
              handleCloseLoading()
              update("", `${Date.now()}___success___Suas cores foram atualizadas com sucesso.`)
              return
            }
          }
          handleCloseLoading()
          update("", `${Date.now()}___error___Não foi possivel alterar suas cores. Por favor verifique sua conexão e tente novamente.`)
        })
        .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimaryS, colorSecondaryS, colorTertiaryS)}>
      <LoadingComponent open={loading} />
      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
        />

        <input
          type="file"
          ref={fileInputRefPromo}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          onChange={handleFileChangePromo}
        />

        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Personalizar</Typography>
            <Button size="medium" variant="contained" onClick={handleSalvar} style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
              Salvar
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent style={{ padding: 24 }}>
                    <Typography style={{ fontSize: 16 }}>
                      <b>Banner</b><br />
                      Aparece acima do perfil da loja na home
                    </Typography>

                    <div style={{ height: 24 }} />

                    {selectedImage ?
                      <img onClick={handleUploadFile} src={selectedImage} alt="Selecionada" style={{ width: "100%", maxHeight: "200px", borderRadius: 8 }} />
                      :
                      <img onClick={handleUploadFile} src={`${urlBase.replaceAll("/api", "")}/imagens/${bannerNormal}?${new Date().getTime()}`} alt="Selecionada" style={{ width: "100%", maxHeight: "200px", borderRadius: 8 }} />
                    }

                    <div style={{ height: 24 }} />

                    <Typography style={{ fontSize: 16 }}>
                      Apenas imagens JPG e PNG até 5MB. <br />
                      Resolução miníma: 700x180
                    </Typography>

                    <div style={{ display: "flex" }}>
                      {selectedImage &&
                        <Button fullWidth size="large" color="error" onClick={handleRemoveFile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>
                          Remover
                        </Button>
                      }
                      <Button fullWidth size="large" onClick={handleUploadFile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<UploadOutlined />}>
                        Carregar foto
                      </Button>
                    </div>
                  </CardContent>
                </Card>

                <div style={{ height: 24 }} />

                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent style={{ padding: 24 }}>
                    <Typography style={{ fontSize: 16 }}>
                      <b>Cor</b><br />
                      Escolha as cores tema de sua loja
                    </Typography>

                    <div style={{ height: 24 }} />
                    <div style={{ display: "flex" }}>
                      <div style={{ padding: 10, border: showPalletP ? "1px #F94A3D solid" : "", background: showPalletP ? "#F5E1E1" : "", borderRadius: 8, height: 94 }}>
                        <Button size="large" style={{ textTransform: 'none', fontWeight: "500", height: 70, width: 70 }} color="primary" variant="contained" onClick={handleOpenPrimary}>
                          Padrão
                        </Button>
                      </div>

                      <div style={{ width: 40 }} />
                      <div style={{ padding: 10, border: showPalletS ? "1px #F94A3D solid" : "", background: showPalletS ? "#F5E1E1" : "", borderRadius: 8, height: 94 }}>
                        <Button size="large" style={{ textTransform: 'none', fontWeight: "500", height: 70, width: 70 }} color="secondary" variant="contained" onClick={handleOpenSecondary}> </Button>
                      </div>

                      <div style={{ width: 24 }} />
                      <div style={{ padding: 10, border: showPalletT ? "1px #F94A3D solid" : "", background: showPalletT ? "#F5E1E1" : "", borderRadius: 8, height: 94 }}>
                        <Button size="large" style={{ textTransform: 'none', fontWeight: "500", height: 70, width: 70 }} color="tertiary" variant="contained" onClick={handleOpenTertiary}> </Button>
                      </div>

                      {!isMobile &&
                        <>
                          <div style={{ width: 24 }} />
                          <div style={{ marginTop: 11 }}>
                            <SketchPicker
                              color={color}
                              onChangeComplete={handleChangeComplete}
                            />
                          </div>
                        </>
                      }
                    </div>

                    {isMobile &&
                      <>
                        <div style={{ marginTop: 24, display: "flex", justifyContent: "center" }}>
                          <SketchPicker
                            color={color}
                            onChangeComplete={handleChangeComplete}
                          />
                        </div>
                      </>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={12}>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardContent style={{ padding: 24 }}>
                    {/* <Typography style={{ fontSize: 16 }}>
                      <b>Banner promocional</b><br />
                      Aparece ao iniciar a home
                    </Typography> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400" }}>
                        <b style={{ fontSize: 16 }}>Banner promocional</b><br />
                        Aparece ao iniciar a home
                      </Typography>
                      <FormControlLabel style={{ marginLeft: "auto" }}
                        control={<MaterialUISwitch sx={{ m: 1 }} checked={bannerOn} onClick={handleBanner} />}
                      />
                    </div>
                    <div style={{ height: 24 }} />

                    {selectedImagePromo ?
                      <img onClick={handleUploadFilePromo} src={selectedImagePromo} alt="Selecionada" style={{ width: "100%", maxHeight: "200px", borderRadius: 8, pointerEvents: !bannerOn ? 'none' : 'auto', opacity: !bannerOn ? 0.5 : 1 }} />
                      :
                      bannerPromocional ?
                        <img onClick={handleUploadFilePromo} src={`${urlBase.replaceAll("/api", "")}/imagens/promo-banner.png?${new Date().getTime()}`} alt="Selecionada" style={{ width: "100%", maxHeight: "200px", borderRadius: 8, pointerEvents: !bannerOn ? 'none' : 'auto', opacity: !bannerOn ? 0.5 : 1 }} />
                        :
                        <img onClick={handleUploadFilePromo} src={`${urlBase.replaceAll("/api", "")}/imagens/promo-banner.png?${new Date().getTime()}`} alt="Selecionada" style={{ pointerEvents: !bannerOn ? 'none' : 'auto', opacity: !bannerOn ? 0.5 : 1, width: "100%", maxHeight: "200px", borderRadius: 8, pointerEvents: !bannerOn ? 'none' : 'auto', opacity: !bannerOn ? 0.5 : 1 }} />
                    }

                    <div style={{ height: 24 }} />

                    <Typography style={{ fontSize: 16 }}>
                      Apenas imagens JPG e PNG até 5MB. <br />
                      Resolução miníma: 512x512
                    </Typography>

                    <div style={{ display: "flex", pointerEvents: !bannerOn ? 'none' : 'auto', opacity: !bannerOn ? 0.5 : 1 }}>
                      {selectedImagePromo &&
                        <Button fullWidth size="large" color="error" onClick={handleRemoveFilePromo} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>
                          Remover
                        </Button>
                      }
                      <Button fullWidth size="large" onClick={handleUploadFilePromo} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<UploadOutlined />}>
                        Carregar foto
                      </Button>
                    </div>
                  </CardContent>
                </Card>

              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </div>
    </ThemeProvider>
  );
}

export default Personalizar;